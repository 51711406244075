/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Sem Reembolso` - Norefund
 * * `Reembolso Parcial` - Partialrefund
 * * `Reembolso Total` - Totalrefund
 */
export enum RefundPolicyEnum {
    SEM_REEMBOLSO = 'Sem Reembolso',
    REEMBOLSO_PARCIAL = 'Reembolso Parcial',
    REEMBOLSO_TOTAL = 'Reembolso Total',
}

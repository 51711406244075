import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  ApiError,
  Campanha,
  CampanhaEstadoEnum,
  CampanhaRequest,
  CampanhaService,
  MarcaService,
  PaisCodeEnum,
} from '../../../slidein_api';
import { useNavigate, useParams } from 'react-router-dom';
import { msgAPIError, paisList } from '../../helpers';
import { useForm, yupResolver } from '@mantine/form';
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  SelectItem,
  Switch,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import {
  IconBorderBottom,
  IconBorderTop,
  IconBracketsContainEnd,
  IconBracketsContainStart,
  IconCoinEuro,
  IconCurrencyEuro,
  IconCurrencyEuroOff,
  IconDeviceFloppy,
  IconEdit,
  IconId,
  IconLetterCase,
  IconLogicNot,
  IconMap,
  IconNotes,
  IconPercentage,
  IconPlane,
  IconPlus,
  IconSunset,
  IconTrash,
  IconTrashX,
  IconX,
} from '@tabler/icons-react';
import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { useBread } from '../../../layout/context/BreadContext';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';

function CampanhaEdit(props: { create: boolean }) {
  const { setBread } = useBread();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { campanhaID } = useParams();

  const [campanha, setCampanha] = useState<Campanha | CampanhaRequest>({
    identificador: '',
    nome: '',
    data_inicio: null,
    data_inicio_viagem: null,
    data_fim: null,
    destino: '',
    pais_code: PaisCodeEnum.PT,
    data_fim_viagem: null,
    data_limite_ultima_prestacao: null,
    estado: CampanhaEstadoEnum.EM_PREPARA_O,
    nr_maximo_de_prestacoes: 6,
    pedir_tshirt: false,
    pedir_peso: false,
    pedir_altura: false,
    pedir_calcado: false,
    notas: '',
    marca: null,
    condicoes_de_cancelamento: '',
    nao_incluido: '',
    mostrar_prestas_clientes: true,
  });

  const [marcas, setMarcas] = useState<SelectItem[]>([]);
  const [docs, setDocs] = useState<SelectItem[]>([]);

  useEffect(() => {
    if (props.create) {
      setBread({
        accao: {
          title: 'Nova Campanha',
          icon: <IconEdit />,
        },
      });
    } else {
      setLoading(true);
      CampanhaService.campanhaRetrieve({ identificador: campanhaID }).then(
        (value) => {
          setLoading(false);
          setCampanha(value);
          campanhaForm.setValues(value);
        },
        (reason: ApiError) => {
          setLoading(false);
          msgAPIError(reason);
        }
      );

      setBread({
        campanha: {
          title: campanhaID,
          href: '/campanha/' + campanhaID,
          icon: <IconMap />,
        },
        accao: {
          title: 'Editar Campanha',
          icon: <IconEdit />,
        },
      });
    }
    MarcaService.marcaList().then(
      (marcas) =>
        setMarcas(
          marcas.map((mm) => {
            return { label: mm.nome, value: mm.id.toString() };
          })
        ),
      (reason) => msgAPIError(reason)
    );
    if (!props.create) {
      CampanhaService.campanhaDocsList({ campanha: campanhaID }).then(
        (values) =>
          setDocs(
            values.map((mm) => {
              return { label: mm.titulo, value: mm.id.toString() };
            })
          ),
        (reason) => msgAPIError(reason)
      );
    }
  }, []);

  const campanhaForm = useForm<CampanhaRequest | Campanha>({
    initialValues: campanha,

    transformValues: (values) => {
      const di = values.data_inicio ? new Date(values.data_inicio) : null;
      const div = values.data_inicio_viagem ? new Date(values.data_inicio_viagem) : null;
      const df = values.data_fim ? new Date(values.data_fim) : null;
      const dfv = values.data_fim_viagem ? new Date(values.data_fim_viagem) : null;
      const dl = values.data_limite_ultima_prestacao
        ? new Date(values.data_limite_ultima_prestacao)
        : null;
      return {
        ...values,
        data_inicio: di
          ? `${di.getFullYear()}-${(di.getMonth() + 1).toString().padStart(2, '0')}-${di
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
        data_inicio_viagem: div
          ? `${div.getFullYear()}-${(div.getMonth() + 1).toString().padStart(2, '0')}-${div
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
        data_fim_viagem: dfv
          ? `${dfv.getFullYear()}-${(dfv.getMonth() + 1).toString().padStart(2, '0')}-${dfv
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
        data_fim: df
          ? `${df.getFullYear()}-${(df.getMonth() + 1).toString().padStart(2, '0')}-${df
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
        data_limite_ultima_prestacao: dl
          ? `${dl.getFullYear()}-${(dl.getMonth() + 1).toString().padStart(2, '0')}-${dl
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
      };
    },

    validate: yupResolver(
      Yup.object({
        identificador: Yup.string()
          .max(16, 'Tamanho máximo de 16 caracteres')
          .min(5, 'Tamanho mínimo de 5 caracteres')
          .matches(
            /^[-a-zA-Z0-9_]+$/,
            'Insira um “id” válido composto por letras (alfabeto inglês), números, sublinhados ou hífens(sem espaços).'
          )
          .required('Obrigatório'),
        nome: Yup.string().required('Obrigatório').min(5, 'Tamanho mínimo de 5 caracteres'),
        destino: Yup.string().required('Obrigatório').min(4, 'Tamanho mínimo de 4 caracteres'),
        data_inicio: Yup.date().required('Obrigatório'),
        data_fim: Yup.date().required('Obrigatório'),
        data_inicio_viagem: Yup.date().required('Obrigatório'),
        data_fim_viagem: Yup.date().required('Obrigatório'),
        data_limite_ultima_prestacao: Yup.date().required('Obrigatório'),
        estado: Yup.string().oneOf(Object.values(CampanhaEstadoEnum)),
      })
    ),
  });

  const saveCampanha = (campanha: CampanhaRequest) => {
    setLoading(true);
    if (props.create) {
      CampanhaService.campanhaCreate({ requestBody: campanha }).then(
        (value) => {
          setLoading(false);
          notifications.show({
            title: 'Campanha Criada!',
            message: `A Campanha ${value.identificador} foi criada.`,
            withCloseButton: true,
            icon: <IconPlane />,
            color: 'green',
          });
          navigate(`/campanha/${value.identificador}`);
        },
        (error) => {
          setLoading(false);
          campanhaForm.setErrors(error.body);
        }
      );
    } else {
      CampanhaService.campanhaUpdate({
        identificador: campanhaID,
        requestBody: campanha,
      }).then(
        (value) => {
          setLoading(false);
          notifications.show({
            title: 'Campanha Actualizada!',
            message: `A Campanha ${value.identificador} foi actualizada.`,
            withCloseButton: true,
            icon: <IconPlane />,
            color: 'green',
          });
          navigate(`/campanha/${value.identificador}`);
        },
        (error) => {
          setLoading(false);
          campanhaForm.setErrors(error.body);
        }
      );
    }
  };

  return (
    <Container p={'md'}>
      <LoadingOverlay
        visible={loading}
        overlayBlur={2}></LoadingOverlay>
      <Title order={2}>{props.create ? 'Nova Campanha' : 'Editar Campanha'}</Title>
      <form onSubmit={campanhaForm.onSubmit(saveCampanha)}>
        {!props.create && (
          <Flex
            direction='row'
            gap={'xs'}
            justify={'space-between'}
            wrap={'wrap'}>
            <Select
              label={'Estado'}
              {...campanhaForm.getInputProps('estado')}
              data={Object.values(CampanhaEstadoEnum)}></Select>
            <Button
              aria-description={'CENAS'}
              disabled={
                (campanha as Campanha).nr_viajantes > 0 || (campanha as Campanha).nr_grupos > 0
              }
              type='button'
              variant={'outline'}
              leftIcon={<IconTrash />}
              color={'red'}
              onClick={() =>
                modals.openConfirmModal({
                  title: 'Remover Campanha',
                  centered: true,
                  children: (
                    <Text size='sm'>
                      Confirma que deseja remover a Campanha : {campanha.identificador} ?
                    </Text>
                  ),
                  labels: { confirm: 'Remover', cancel: 'Cancelar' },
                  confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconTrash /> },
                  cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                  onConfirm: () => {
                    CampanhaService.campanhaDestroy({ identificador: campanhaID }).then(
                      () => {
                        notifications.show({
                          title: 'Campanha removida!',
                          message: `A Campanha ${campanhaID} foi removida.`,
                          withCloseButton: true,
                          icon: <IconTrashX />,
                          color: 'green',
                        });
                      },
                      (reason: ApiError) => {
                        if (reason.status == 423) {
                          notifications.show({
                            title: 'Campanha!',
                            message: `A Campanha  ${campanhaID} NÃO foi removida, existem Viagens/Grupos associados!`,
                            withCloseButton: true,
                            icon: <IconX />,
                            color: 'yellow',
                            autoClose: 5000,
                          });
                        } else {
                          msgAPIError(reason);
                        }
                      }
                    );
                  },
                })
              }>
              Remover Campanha
            </Button>
          </Flex>
        )}
        <Flex
          direction='row'
          gap={'xs'}
          justify={'space-between'}
          wrap={'wrap'}>
          <TextInput
            w={{ base: '100%', xs: '49%' }}
            label={'Identificador'}
            disabled={!props.create}
            required
            icon={<IconId />}
            {...campanhaForm.getInputProps('identificador')}
          />
          <Select
            w={{ base: '100%', xs: '49%' }}
            data={marcas}
            label={'Marca'}
            {...campanhaForm.getInputProps('marca')}
            value={
              campanhaForm.values.marca ? campanhaForm.values.marca.toString() : null
            }></Select>
          <TextInput
            w={{ base: '100%', xs: '100%' }}
            label={'Nome da Campanha'}
            required
            icon={<IconLetterCase />}
            {...campanhaForm.getInputProps('nome')}
          />
          <TextInput
            w={{ base: '100%', xs: '49%' }}
            label={'Destino'}
            required
            icon={<IconSunset />}
            {...campanhaForm.getInputProps('destino')}
          />
          <Select
            w={{ base: '100%', xs: '49%' }}
            data={paisList}
            label={'País'}
            required
            {...campanhaForm.getInputProps('pais_code')}></Select>
        </Flex>

        <Flex
          direction='row'
          gap={'xs'}
          mt={'xs'}
          justify={'flex-start'}
          wrap={'wrap'}>
          <DatePickerInput
            label=' Data de Inicio da Viagem'
            w={{ base: '100%', xs: '32%' }}
            locale={'pt'}
            firstDayOfWeek={1}
            placeholder='Escolher Data'
            icon={<IconBracketsContainStart stroke={1.5} />}
            valueFormat='DD/MM/YYYY'
            {...campanhaForm.getInputProps('data_inicio_viagem')}
            value={
              campanhaForm.values.data_inicio_viagem
                ? dayjs(campanhaForm.values.data_inicio_viagem).toDate()
                : null
            }
          />
          <DatePickerInput
            label=' Data de Fim da Viagem'
            w={{ base: '100%', xs: '32%' }}
            locale={'pt'}
            firstDayOfWeek={1}
            placeholder='Escolher Data'
            minDate={
              campanhaForm.values.data_inicio_viagem
                ? new Date(campanhaForm.values.data_inicio_viagem)
                : null
            }
            icon={<IconBracketsContainEnd stroke={1.5} />}
            valueFormat='DD/MM/YYYY'
            {...campanhaForm.getInputProps('data_fim_viagem')}
            value={
              campanhaForm.values.data_fim_viagem
                ? dayjs(campanhaForm.values.data_fim_viagem).toDate()
                : null
            }
          />
        </Flex>

        <Flex
          direction='row'
          gap={'xs'}
          mt={'xs'}
          justify={'flex-start'}
          wrap={'wrap'}>
          <DatePickerInput
            label=' Data de Inicio inscrições/venda'
            w={{ base: '100%', xs: '32%' }}
            locale={'pt'}
            firstDayOfWeek={1}
            placeholder='Escolher Data'
            icon={<IconBracketsContainStart stroke={1.5} />}
            valueFormat='DD/MM/YYYY'
            {...campanhaForm.getInputProps('data_inicio')}
            value={
              campanhaForm.values.data_inicio
                ? dayjs(campanhaForm.values.data_inicio).toDate()
                : null
            }
          />
          <DatePickerInput
            label=' Data de Fim inscrições/venda'
            w={{ base: '100%', xs: '32%' }}
            locale={'pt'}
            firstDayOfWeek={1}
            placeholder='Escolher Data'
            minDate={
              campanhaForm.values.data_inicio ? new Date(campanhaForm.values.data_inicio) : null
            }
            icon={<IconBracketsContainEnd stroke={1.5} />}
            valueFormat='DD/MM/YYYY'
            {...campanhaForm.getInputProps('data_fim')}
            value={
              campanhaForm.values.data_fim ? dayjs(campanhaForm.values.data_fim).toDate() : null
            }
          />
          <DatePickerInput
            label='Data de limite última prestação'
            w={{ base: '100%', xs: '32%' }}
            locale={'pt'}
            firstDayOfWeek={1}
            placeholder='Escolher Data'
            icon={<IconCoinEuro stroke={1.5} />}
            valueFormat='DD/MM/YYYY'
            {...campanhaForm.getInputProps('data_limite_ultima_prestacao')}
            value={
              campanhaForm.values.data_limite_ultima_prestacao
                ? dayjs(campanhaForm.values.data_limite_ultima_prestacao).toDate()
                : null
            }
          />
        </Flex>
        <Divider
          label={<Text fz='xl'>Prestações</Text>}
          labelPosition='center'
        />
        <Flex
          direction={'row'}
          justify={'flex-start'}
          wrap={'wrap'}
          gap={'xl'}>
          <Checkbox
            my={'md'}
            labelPosition='left'
            label='Mostrar Prestações'
            color='green'
            size='md'
            {...campanhaForm.getInputProps('mostrar_prestas_clientes', { type: 'checkbox' })}
          />

          <NumberInput
            label={'Número Máximo de Prestações'}
            {...campanhaForm.getInputProps('nr_maximo_de_prestacoes')}
          />
        </Flex>
        <Divider
          label={<Text fz='xl'>Informações Logísticas/Promocionais</Text>}
          labelPosition='center'
        />
        <Flex
          direction={'row'}
          justify={'flex-start'}
          wrap={'wrap'}
          gap={'xl'}>
          <Checkbox
            my={'md'}
            labelPosition='left'
            label='Pedir Tamanho de T-shirt'
            color='green'
            size='md'
            {...campanhaForm.getInputProps('pedir_tshirt', { type: 'checkbox' })}
          />
          <Checkbox
            my={'md'}
            labelPosition='left'
            label='Pedir Peso'
            color='green'
            size='md'
            {...campanhaForm.getInputProps('pedir_peso', { type: 'checkbox' })}
          />
          <Checkbox
            my={'md'}
            labelPosition='left'
            label='Pedir Altura'
            color='green'
            size='md'
            {...campanhaForm.getInputProps('pedir_altura', { type: 'checkbox' })}
          />
          <Checkbox
            my={'md'}
            labelPosition='left'
            label='Pedir Tamanho de Calçado'
            color='green'
            size='md'
            {...campanhaForm.getInputProps('pedir_calcado', { type: 'checkbox' })}
          />
        </Flex>
        <Divider
          label={<Text fz='xl'>Custos de Operação nos Pagamentos</Text>}
          labelPosition='center'
        />
        <Title order={4}>Referências Multibanco</Title>
        <Text
          fz={'sm'}
          fs={'italic'}>
          Valores sem iva.(aplicado posteriormente)
        </Text>
        <Flex
          direction={'row'}
          justify={'flex-start'}
          align={'center'}
          wrap={'wrap'}
          gap={'xl'}>
          <NumberInput
            placeholder='€'
            label='Custo fixo'
            precision={2}
            icon={<IconCurrencyEuro />}
            min={0}
            defaultValue={0}
            hideControls
            decimalSeparator=','
            thousandsSeparator='.'
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value))
                ? // eslint-disable-next-line security/detect-unsafe-regex
                  `${value} €`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            {...campanhaForm.getInputProps('custo_oper_fixo_pref')}
          />
          <IconPlus />
          <NumberInput
            placeholder='%'
            label='Custo Variável'
            precision={2}
            min={0}
            max={100}
            defaultValue={0}
            icon={<IconPercentage />}
            hideControls
            decimalSeparator=','
            thousandsSeparator='.'
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value))
                ? // eslint-disable-next-line security/detect-unsafe-regex
                  `${value} %`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            {...campanhaForm.getInputProps('custo_oper_perc_pref')}
          />
        </Flex>
        <Flex
          direction={'row'}
          justify={'flex-start'}
          wrap={'wrap'}
          gap={'xl'}>
          <NumberInput
            placeholder='€'
            label='Valor Mínimo'
            precision={2}
            min={0}
            icon={<IconBorderBottom />}
            hideControls
            decimalSeparator=','
            thousandsSeparator='.'
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value))
                ? // eslint-disable-next-line security/detect-unsafe-regex
                  `${value} €`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            value={
              campanhaForm.values.custo_oper_min_pref !== null
                ? campanhaForm.values.custo_oper_min_pref
                : ''
            }
            onChange={(value) => {
              if (value !== '') {
                campanhaForm.setFieldValue('custo_oper_min_pref', value);
              } else {
                campanhaForm.setFieldValue('custo_oper_min_pref', null);
              }
            }}
          />
          <NumberInput
            placeholder='€'
            label='Valor Máximo'
            precision={2}
            icon={<IconBorderTop />}
            hideControls
            min={0}
            decimalSeparator=','
            thousandsSeparator='.'
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value))
                ? // eslint-disable-next-line security/detect-unsafe-regex
                  `${value} €`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            value={
              campanhaForm.values.custo_oper_max_pref !== null
                ? campanhaForm.values.custo_oper_max_pref
                : ''
            }
            onChange={(value) => {
              if (value !== '') {
                campanhaForm.setFieldValue('custo_oper_max_pref', value);
              } else {
                campanhaForm.setFieldValue('custo_oper_max_pref', null);
              }
            }}
          />
        </Flex>

        <Divider my={'md'} />
        <Switch
          labelPosition='left'
          label={'Cancelamento na interface de clientes.'}
          size={'xl'}
          color='green'
          onLabel='PERMITIDO'
          offLabel='NÂO PERMITIDO'
          radius='sm'
          {...campanhaForm.getInputProps('is_cancel_allowed', { type: 'checkbox' })}
        />

        <Textarea
          placeholder='...'
          mt={'xs'}
          label='Condições Cancelamento:(Aparece no email)'
          size='md'
          autosize
          minRows={2}
          icon={<IconCurrencyEuroOff />}
          {...campanhaForm.getInputProps('condicoes_de_cancelamento')}
        />
        <Textarea
          placeholder='...'
          mt={'xs'}
          label='Não incluido'
          size='md'
          autosize
          minRows={2}
          icon={<IconLogicNot />}
          {...campanhaForm.getInputProps('nao_incluido')}
        />
        {!props.create && (
          <Select
            mt={'xs'}
            w={{ base: '100%', xs: '100%' }}
            data={docs}
            label={'Autorização de Saída de Menores (Escolher da lista de ficheiros da Campanha)'}
            {...campanhaForm.getInputProps('minuta_menores')}
            value={
              campanhaForm.values.minuta_menores
                ? campanhaForm.values.minuta_menores.toString()
                : null
            }></Select>
        )}
        <Textarea
          mt={'xs'}
          placeholder='Notas...'
          label='Notas:'
          size='md'
          autosize
          minRows={2}
          icon={<IconNotes />}
          {...campanhaForm.getInputProps('notas')}
        />
        <Group
          position='right'
          mt='md'>
          <Button
            hidden={!props.create}
            type='button'
            variant={'outline'}
            leftIcon={<IconX />}
            color={'yellow'}
            onClick={() => navigate(-1)}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            leftIcon={<IconDeviceFloppy />}
            type='submit'>
            {props.create ? 'Criar Campanha' : 'Guardar Alterações'}
          </Button>
        </Group>
      </form>
    </Container>
  );
}

export default CampanhaEdit;
